<template>
  <div class="dataReporting">
    <div class="facilityBox" v-show="!flag.riskVerifying">
      <!-- 表格区域 -->
      <el-form
        class="searchForm"
        inline
        :model="searchForm"
        ref="searchForm"
        label-width="80px"
      >
        <el-form-item label="服务商名称" prop="userID" label-width="100px">
          <el-select
            v-model="searchForm.userID"
            placeholder="请选择服务商"
            @change="changeSupplier"
            style="width: 200px"
          >
            <el-option
              v-for="item in accountList"
              :key="item.UserID"
              :label="item.Name"
              :value="item.UserID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货主名称" prop="ownerName">
          <el-input v-model="searchForm.ownerName" placeholder="请输入货主名称">
          </el-input>
        </el-form-item>
        <el-form-item label="运单号" prop="waybillID">
          <el-input v-model="searchForm.waybillID" placeholder="请输入运单号">
          </el-input>
        </el-form-item>

        <el-form-item prop="statusType" style="margin-left: 20px">
          <el-select v-model="searchForm.statusType" style="width: 130px">
            <!-- <el-option label="全部" value=""></el-option> -->
            <el-option label="上报司机" value="0"></el-option>
            <el-option label="上报车辆" value="1"></el-option>
            <el-option label="上报运单" value="2"></el-option>
            <el-option label="上报资金" value="3"></el-option>
          </el-select>
          <el-select v-model="searchForm.status" style="width: 130px">
            <el-option label="已上报" value="1"></el-option>
            <el-option label="未上报" value="0"></el-option>
            <el-option label="上报失败" value="2"></el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item
            label="开票申请编号"
            prop="InvoiceTaskID"
            label-width="100px"
          >
            <el-input
              v-model="searchForm.InvoiceTaskID"
              placeholder="请输入开票申请编号"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="车牌号码" prop="carNumber">
            <el-input
              v-model="searchForm.carNumber"
              placeholder="请输入车牌号码"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="司机信息" prop="driverNameOrIdCard">
            <el-input
              v-model="searchForm.driverNameOrIdCard"
              placeholder="请输入司机手机号/身份证"
            >
            </el-input>
          </el-form-item>
          <el-form-item label-width="10px" style="margin-left: 20px">
            <div style="white-space: nowrap">
              <el-button
                type="primary"
                :disabled="loading"
                size="medium"
                @click="search('searchForm')"
                icon="el-icon-search"
                >搜索</el-button
              >
              <el-button
                type="primary"
                :disabled="loading"
                size="medium"
                icon="el-icon-delete"
                @click="resetForm('searchForm')"
                >清空</el-button
              >
              <el-button
                icon="el-icon-upload2"
                type="primary"
                :disabled="!trafficlist.length"
                size="medium"
                @click="upTrafficAll('1')"
                >批量上报</el-button
              >
            </div>
          </el-form-item>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item label="完成时间" prop="finishTime" label-width="100px">
            <el-date-picker
              v-model="searchForm.finishTime"
              type="datetimerange"
              start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <div style="color: red">
              提示：只允许上报<span style="font-weight: bold">
                {{ reportCompanyName }} </span
              >下
              <span style="font-weight: bold">承运业务线上付款已完成流程</span>
              的运单。
            </div>
          </el-form-item>
        </div>
      </el-form>

      <!-- 表格数据 -->
      <el-table
        ref="table"
        row-key="WaybillID"
        :data="tableData"
        v-loading="loading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        @selection-change="tableSelectionChange"
      >
        <el-table-column
          type="selection"
          :selectable="selectable"
          :reserve-selection="true"
          align="center"
          width="55"
          fixed="left"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          fixed
          align="center"
          type="index"
          prop="index"
          label="序号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillID"
          label="运单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTaskID"
          label="开票申请编号"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              @click="
                $router.push(
                  `/finance/statistics/statisticsInfo?InvoiceTaskID=${scope.row.InvoiceTaskID}`
                )
              "
            >
              {{ scope.row.InvoiceTaskID }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageBillID"
          label="客户单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="OwnerName"
          label="货主单位"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div @click="showSupplyDialog(scope, 2)">
              <el-link
                type="primary"
                v-html="showNameHighlight(scope.row.OwnerName)"
              ></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTypeName"
          label="发票类型"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ImportTime"
          label="运单导入时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceApplyDatetime"
          label="开票申请时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayDatetime"
          label="确认支付时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoicePrintDatetime"
          label="开票完成时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceNumber"
          label="发票号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SupplierName"
          label="服务商"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div @click="showSupplyDialog(scope, 1)">
              <el-link
                type="primary"
                v-html="showSupplyNameHighlight(scope.row.SupplierName)"
              ></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageTotalPrice"
          label="运费"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ExpensesOfTaxation"
          label="服务费/税费"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TotalAmount"
          label="运单总金额"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          label="司机姓名"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div @click="getPersonInfo(scope.row, scope.row.DriverUserID)">
              <el-link type="primary">{{ scope.row.DriverName }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarNumber"
          label="车牌"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div @click="showVehicleInfo(scope.row)">
              <el-link
                type="primary"
                v-html="showCarNumberHighlight(scope.row.CarNumber)"
              ></el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="480">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              @click="upTraffic('driver', scope.row)"
              :disabled="scope.row.DriverStatus == 1"
              >上报司机</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="upTraffic('car', scope.row)"
              :disabled="scope.row.CarStatus == 1"
              >上报车辆
            </el-button>
            <el-button
              size="small"
              type="primary"
              @click="upTraffic('waybill', scope.row)"
              :disabled="scope.row.BillStatus == 1"
              >上报运单
            </el-button>
            <el-button
              size="small"
              type="primary"
              @click="upTraffic('capital', scope.row)"
              :disabled="scope.row.MoneyStatus == 1"
              >上报资金
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :loading="flag.openDetailLoading"
              @click="openDetailDialog(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="display: flex; align-items: center">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
      <el-dialog
        class="voucherDialog"
        title="运单信息"
        center
        :visible.sync="flag.detailDialog"
        width="1200px"
        v-if="flag.detailDialog"
        :close-on-click-modal="false"
      >
        <EvidenceInfo
          :businessID="currentItem.WaybillID"
          :isShowDetailInfo="true"
          :isShowFiveInfo="true"
          :currentItem="bascicInfo"
          :detailInfo="detailInfo"
        >
        </EvidenceInfo>
      </el-dialog>
      <!--服务商/货主信息弹框-->
      <el-dialog
        top="15vh"
        class="dialog"
        :visible.sync="supplyDialog"
        width="1000px"
        :close-on-click-modal="false"
      >
        <span slot="title">{{ type == 1 ? "服务商信息" : "货主信息" }}</span>
        <div>
          <SupplyInfo v-if="type == 1" :supplyId="supplyId"></SupplyInfo>
          <BossInfo v-if="type == 2" :supplyId="supplyId" />
        </div>
      </el-dialog>
      <!--人员信息弹框-->
      <el-dialog
        top="15vh"
        class="dialog"
        :visible.sync="personDialog"
        width="1300px"
        :close-on-click-modal="false"
      >
        <span slot="title">人员信息</span>
        <DriverInfo :driverInfo="driverInfo" />
      </el-dialog>
      <!--显示车辆信息弹框-->
      <el-dialog
        top="15vh"
        class="dialog"
        :visible.sync="vehicleDialog"
        width="1300px"
        :close-on-click-modal="false"
      >
        <span slot="title">车辆信息</span>
        <VehicleInfo :vehicleInfo="vehicleInfo" />
      </el-dialog>
      <!-- 上报运单错误信息dialog -->
      <el-dialog
        :visible.sync="flag.uploadErrorDialog"
        title="错误信息"
        center
        append-to-body
      >
        <div style="height: 60vh; overflow-y: auto">
          <el-table
            :data="uploadErrorList"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          >
            <el-table-column
              align="center"
              prop="WaybillID"
              label="错误运单"
              width="200"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Msgs"
              label="错误信息"
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
        </div>
        <div style="width: 100%; text-align: center; margin-top: 20px">
          <el-button type="primary" @click="flag.uploadErrorDialog = false"
            >确定
          </el-button>
          <el-button type="primary" @click="upTrafficAll('2')">上报 </el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 批量上报 -->
    <div class="riskVerify" v-if="flag.riskVerifying">
      <el-progress
        :width="400"
        type="circle"
        :stroke-width="20"
        :percentage="flag.riskProgress"
      ></el-progress>
      <p class="desc">上报中，请稍后...</p>
    </div>
  </div>
</template>

<script>
import {
  getAllBillList,
  queryWayBillDetail,
} from "@/api/waybill/otherAndAllBill/index";
import {
  getDriverDetails,
  getCarInfo,
  GetSysValueAsync,
} from "@/api/common/common";
import {
  GetBills,
  ReportCar,
  ReportDriver,
  ReportWaybill,
  ReportCaptial,
  ReportBatch,
  GetThirdAccountList,
} from "@/api/platform/dataReporting/index";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
import SupplyInfo from "@/components/allWayBillCmpt/supplyInfoDialog";
import BossInfo from "@/components/allWayBillCmpt/bossInfoDialog";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import basicMixin from "@/mixins/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      reportCompanyName: "", // 上报公司名
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      accountList: [], //交通厅服务商列表
      // 搜索表单
      searchForm: {
        ownerName: "",
        waybillID: "",
        InvoiceTaskID: "",
        carNumber: "",
        driverNameOrIdCard: "",
        statusType: "0",
        status: "1",
        finishTime: ["", ""],
        userID: "",
      },
      tableData: [], //表格数据
      loading: false,
      currentItem: null, //当前点击的哪一条运单
      detailInfo: {}, //五流合一详情信息
      mapList: [], //轨迹列表
      trafficlist: [],
      // 各种详情弹框
      supplyDialog: false, // 服务商/货主信息弹框
      supplyId: "", // 服务商/货主id
      type: "", // 根据类型显示弹框 1是服务商 2是货主
      personDialog: false, // 人员信息弹框
      driverInfo: {}, //  司机 收款人信息
      vehicleInfo: {}, // 车辆信息
      vehicleDialog: false, // 车辆信息弹框
      flag: {
        openDetailLoading: false, // 五流合一接口请求
        detailDialog: false, //详情弹窗
        riskVerifying: false,
        riskProgress: 0,
        uploadErrorDialog: false,
      },
      uploadErrorList: [],
      bascicInfo: {},
    };
  },
  methods: {
    getThirdAccountList() {
      GetThirdAccountList().then((res) => {
        this.accountList = res.data || [];
        this.searchForm.userID = this.accountList[0].UserID;
        this.reportCompanyName = this.accountList[0].Name;
        //获取全部运单列表
        this.getAllBillList();
      });
    },
    changeSupplier() {
      this.reportCompanyName = this.accountList.filter(
        (it) => it.UserID == this.searchForm.userID
      )[0].Name;
    },
    selectable(row, index) {
      return true;
    },
    // 货主名称搜索高亮
    showNameHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.companyName) !== -1 &&
          this.searchForm.companyName !== ""
        ) {
          return val.replace(
            this.searchForm.companyName,
            '<font color="red">' + this.searchForm.companyName + "</font>"
          );
        } else {
          return val;
        }
      } else {
        return val;
      }
    },
    // 服务商名称高亮
    showSupplyNameHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.companyName) !== -1 &&
          this.searchForm.companyName !== ""
        ) {
          return val.replace(
            this.searchForm.companyName,
            '<font color="red">' + this.searchForm.companyName + "</font>"
          );
        } else {
          return val;
        }
      } else {
        return val;
      }
    },
    // 车牌号高亮
    showCarNumberHighlight(val) {
      if (val) {
        if (
          val.indexOf(this.searchForm.carNumber) !== -1 &&
          this.searchForm.carNumber !== ""
        ) {
          return val.replace(
            this.searchForm.carNumber,
            '<font color="red">' + this.searchForm.carNumber + "</font>"
          );
        } else {
          return val;
        }
      }
    },
    // 上报
    upTraffic(type, item) {
      this.$confirm("确定上报吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ajaxFun = "";
        let params = {
          waybillID: item.WaybillID,
        };
        if (type == "driver") {
          ajaxFun = ReportDriver;
          params = {
            driverID: item.DriverUserID,
          };
        } else if (type == "capital") {
          ajaxFun = ReportCaptial;
        } else if (type == "car") {
          ajaxFun = ReportCar;
          params = {
            carNumber: item.CarNumber,
          };
        } else if (type == "waybill") {
          ajaxFun = ReportWaybill;
        }
        params.userID = this.searchForm.userID;
        const loading = this.$loading({
          lock: true,
          text: "上报中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ajaxFun(params)
          .then((res) => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getAllBillList();
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    // 批量上报
    upTrafficAll(type) {
      this.flag.uploadErrorDialog = false;
      let paramsArr = type == 1 ? this.trafficlist : this.uploadErrorList;
      //开始上报
      if (type == 2) {
        this.uploadErrorList = [];
      }
      const loading = this.$loading({
        lock: true,
        text: "上报中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ReportBatch({
        waybillID: paramsArr.map((i) => {
          return i.WaybillID;
        }),
        userID: this.searchForm.userID,
      })
        .then((res) => {})
        .catch((res) => {
          this.uploadErrorList = res.data.data;
        })
        .finally(() => {
          if (this.uploadErrorList.length) {
            this.flag.uploadErrorDialog = true;
          }
          //上报完毕后需要刷新列表获取新数据
          this.getAllBillList();
          this.$message({
            message: "操作成功",
            type: "success",
          });
          loading.close();
          this.$refs.table.clearSelection();
        });
      // paramsArr.forEach(el => {
      //   ReportBatch({ waybillID: el.WaybillID })
      //     .then(res => { })
      //     .catch(res => {
      //       this.uploadErrorList.push(res.data.data)
      //     })
      //     .finally(() => {
      //       progress = progress + 1
      //       this.flag.riskProgress = parseInt(((progress / paramsArr.length) * 100))
      //       if (this.flag.riskProgress == 100) {
      //         this.flag.riskVerifying = false
      //         if (this.uploadErrorList.length) {
      //           this.flag.uploadErrorDialog = true
      //         }
      //         //上报完毕后需要刷新列表获取新数据
      //         this.getAllBillList()
      //         this.$message({
      //           message: "操作成功",
      //           type: "success",
      //         });
      //         this.$refs.table.clearSelection();
      //       }
      //     })
      // })
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.tableData = [];
      this.loading = true;
      this.getAllBillList();
    },
    //清空
    resetForm() {
      this.loading = true;
      this.searchForm = {
        ownerName: "",
        waybillID: "",
        InvoiceTaskID: "",
        carNumber: "",
        driverNameOrIdCard: "",
        statusType: "0",
        status: "1",
        finishTime: ["", ""],
      };
      this.pagination.page = 1;
      this.getAllBillList();
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.trafficlist = e;
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      this.getAllBillList();
    },
    //获取所有运单列表
    getAllBillList() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        ownerName: this.searchForm.ownerName,
        userID: this.searchForm.ownerName,
        waybillID: this.searchForm.waybillID,
        InvoiceTaskID: this.searchForm.InvoiceTaskID,
        carNumber: this.searchForm.carNumber,
        driverNameOrIdCard: this.searchForm.driverNameOrIdCard,
        statusType: this.searchForm.statusType,
        status: this.searchForm.status,
        invoicePrintDatetimeStart: this.searchForm.finishTime
          ? this.searchForm.finishTime[0]
          : "",
        invoicePrintDatetimeEnd: this.searchForm.finishTime
          ? this.searchForm.finishTime[1]
          : "",
        userID: this.searchForm.userID,
      };
      this.loading = true;
      GetBills(params)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //打开详情弹窗
    openDetailDialog(e) {
      this.currentItem = e;
      let params = {
        waybillId: e.WaybillID,
      };
      this.flag.openDetailLoading = true;
      queryWayBillDetail(params)
        .then((res) => {
          res.data.Vouchers.forEach((item, index) => {
            item.Name = `付款凭证${index + 1}`;
          });
          this.detailInfo = res.data;
          let params1 = {
            pageSize: 10,
            pageIndex: 1,
            taskType: "01",
            WaybillId: e.WaybillID,
            roleType: 1,
            wayBillStatus: -1,
            ManualStatus: 0,
          };
          getAllBillList(params1)
            .then((res1) => {
              this.flag.detailDialog = true;
              this.flag.openDetailLoading = false;
              this.bascicInfo =
                res1.data.DataList && res1.data.DataList.length
                  ? res1.data.DataList[0]
                  : {};
            })
            .finally(() => {
              this.flag.openDetailLoading = false;
            });
        })
        .catch(() => {
          this.flag.openDetailLoading = false;
        });
    },
    // 显示各种弹框
    // 显示服务商/货主信息弹框
    showSupplyDialog(scope, index) {
      this.currentItem = scope.row;
      this.type = index;
      if (index == 1) {
        // 服务商信息
        if (!this.currentItem.SupplierUserID) return;
        this.supplyId = this.currentItem.SupplierUserID;
      } else if (index == 2) {
        // 货主信息
        if (!this.currentItem.OwnerUserID) return;
        this.supplyId = this.currentItem.OwnerUserID;
      }
      this.supplyDialog = true;
    },
    // 查询人员信息
    getPersonInfo(item, UserID) {
      this.loading = true;
      if (item) {
        this.currentItem = item;
      }
      getDriverDetails({ UserID,AscriptionUserID: item.OwnerUserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.personDialog = true;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取车辆信息
    showVehicleInfo(item) {
      let getVehicleInfoed = false; //是否拿到了车子信息
      let getCarInfoed = false; //是否拿到了车子证件照
      let params = {}; // 车辆信息
      getVehicleInfoed = true;
      let params2 = {
        CarNumber: item.CarNumber,
      };
      getCarInfo(params2)
        .then((res) => {
          this.vehicleInfo = Object.assign(
            params,
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          getCarInfoed = true;
          console.log(params);
        })
        .finally(() => {
          if (getVehicleInfoed && getCarInfoed) {
            this.vehicleDialog = true;
          }
        });
    },
  },
  created() {
    this.getThirdAccountList();
    // // 获取上报公司名
    // GetSysValueAsync({
    //   keyType: 'ReportCompany',
    //   keyName: 'ReportCompany'
    // }).then(res => {
    //   this.reportCompanyName = res.data ? res.data.KeyText : ''
    // })
  },
  components: {
    EvidenceInfo,
    SupplyInfo,
    DriverInfo,
    VehicleInfo,
    BossInfo,
  },
};
</script>

<style lang="scss">
.voucherDialog,
.dialog {
  .el-dialog {
    top: -10%;
  }
}

.dataReporting .el-table__fixed-right {
  height: 100% !important;
}

.dataReporting .el-table__footer-wrapper tbody td.columnStyle {
  color: rgb(255, 139, 23);
}

.el-table .blue {
  background: #ecf2ff;
}

.el-table .orange {
  background: #fff9f3;
}

.dataReporting .el-dialog__body {
  padding-top: 5px !important;
  max-height: 80vh;
  overflow: auto;
}

.dataReporting .el-dialog__wrapper {
  overflow: hidden;
}
</style>

<style scoped lang="scss">
.dataReporting {
  .riskVerify {
    height: calc(100vh - 150px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;

    .desc {
      font-size: 30px;
      font-weight: 700;
      margin-top: 30px;
    }
  }

  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .head {
    display: flex;

    .btn {
      vertical-align: top;
      margin: 15px 0 0 16px;
    }
  }

  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .uploadArea {
      margin-top: 20px;

      .confirmInfoForm {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .hint {
      display: flex;
      justify-content: center;
    }

    .choose {
      display: flex;
      justify-content: center;
    }
  }

  .btns {
    margin-bottom: 20px;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }

  .addForm {
    display: flex;
    flex-wrap: wrap;

    .el-form-item {
      width: 450px;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }

  .pagination {
    margin-top: 10px;
  }
}
</style>
